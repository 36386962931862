import React, { useEffect, useState } from 'react';
import Container from '../components/Container';
import { GrBasket } from "react-icons/gr";
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../app/features/user/userSlice';
import { Link } from 'react-router-dom';
import { getAllSizes } from '../app/features/size/sizeSlice';
import SkeletonLoader from '../components/SkeletonLoader';  // Import SkeletonLoader

const Orders = () => {
  const dispatch = useDispatch();

  // Fetch orders and sizes on component mount
  useEffect(() => {
    dispatch(getOrders());
    dispatch(getAllSizes());
  }, [dispatch]);

  const orderState = useSelector(state => state?.auth?.getOrderedProduct);
  const sizeState = useSelector(state => state?.size?.sizes);

  const [openDetails, setOpenDetails] = useState(null); // Track which order's details are open
  const [isLoading, setIsLoading] = useState(true);  // Manage loading state

  // Function to get size title by sizeId(s)
  const getSizeTitleById = (sizeIds) => {
    if (Array.isArray(sizeIds)) {
      return sizeIds.map(id => {
        const size = sizeState?.find(size => size._id === id);
        return size ? size.title : 'Unknown';
      }).join(', ');
    } else {
      const size = sizeState?.find(size => size._id === sizeIds);
      return size ? size.title : 'Unknown';
    }
  };

  const toggleDetails = (orderIndex) => {
    // Toggle visibility of order details
    setOpenDetails(prevIndex => (prevIndex === orderIndex ? null : orderIndex));
  };

  // Sort the orders in descending order based on the createdAt field
  const sortedOrders = orderState
    ? [...orderState].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    : [];

  // Format the date of the latest order
  const latestOrderDate = sortedOrders.length > 0 ? new Date(sortedOrders[0].createdAt).toLocaleDateString() : 'N/A';

  useEffect(() => {
    // Check if both orders and sizes are available before turning off loading
    if (orderState && sizeState && orderState.length && sizeState.length) {
      setIsLoading(false);
    }
  }, [orderState, sizeState]); // Trigger when data is fetched

  return (
    <Container class1='section-11 pb-40'>
      <div>
        <div className='col-122 mb-10'>
          <div className='sty-ba'><GrBasket className='basket' /></div>
          <h1>Order Details</h1>
          <p>{latestOrderDate}</p>
        </div>

        <div className='order-sum'>
          <strong>Hello {orderState[0]?.user?.firstname} {orderState?.user?.lastname}, here are your Recent Orders</strong>
          <p>Here is a summary of your recent order made on {latestOrderDate}</p>
        </div>

        {isLoading ? (
          <SkeletonLoader />  // Show skeleton loader while loading
        ) : (
          sortedOrders && sortedOrders.map((item, index) => (
            <div className='order-c' key={index}>
              <div className='d-flex flex-column gap-10 border-order'>
                {/* Mapping through all items in the order */}
                {item?.orderItems?.map((i, idx) => (
                  <div key={idx} className="d-row">
                    <div className="cart-diss">
                      <img src={i?.product?.images[0]?.url} alt="" className="w-100" />
                    </div>
                    <div className='order-details'>
                      <div className='col-1'><p>{i?.product?.title}</p></div>
                      <div className='col-1'><p>Quantity: {i?.quantity}</p></div>
                      <div className='col-1' dangerouslySetInnerHTML={{ __html: i?.product?.description }} />
                      <div className='col-111'><p className='col-00'>{item?.orderStatus}</p></div>
                      <div className='col-1 mb-5'>
                        <ul className="colors">
                          <li style={{ backgroundColor: i?.product?.color?.title }}></li>
                        </ul>
                      </div>
                      <div className='d-flex'>
                        <div className='col-1'>
                          <p>{getSizeTitleById(i?.size)}</p>
                        </div>
                      </div>

                      <div className='see-det'>
                        {/* Toggle the visibility of the order details */}
                        <Link to="#" onClick={() => toggleDetails(index)} className='see-l'>
                          {openDetails === index ? 'Hide Details' : 'See Details'}
                        </Link>
                        <div className='col-1 mb-10 b-bott'><p>UGX {item?.totalPrice}</p></div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Conditionally display additional items based on toggle state */}
                {openDetails === index && (
                  <div className='order-items-container'>
                    <div className='ship mt-10'>
                      <div className='col-1'><p>Payment Method: {item?.paymentInfo?.paymentMethod || 'N/A'}</p></div>
                      <div className='col-1'><p>Name: {item?.user?.firstname || 'N/A'} {item?.user?.lastname || 'N/A'}</p></div>
                      <div className='col-1'><p>Mobile: {item?.user?.mobile || 'N/A'}</p></div>
                      <div className='col-1'><p>Shipping Address: {item?.shippingInfo?.address}</p></div>
                      <div className='col-1'><p>Region: {item?.shippingInfo?.region}</p></div>
                      <div className='col-1'><p>Sub-region: {item?.shippingInfo?.subRegion}</p></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </Container>
  );
}

export default Orders;
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Container from '../components/Container';
import { deleteUserCart, getUserCart, resetState, createAnOrder } from '../app/features/user/userSlice';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';
import { getPprices } from '../app/features/printPrice/printPriceSlice';


// Yup validation schema
const shippingSchema = yup.object({
  firstName: yup.string().required('First Name is Required'),
  lastName: yup.string().required('Last Name is Required'),
  address: yup.string().required('Address Details are Required'),
  region: yup.string().required('Region is Required'),
  subRegion: yup.string().required('Sub Region is Required'),
  paymentMethod: yup.string().required('Payment Method is Required'),
});

const shippingPrices = {
  'Kampala Region': 5000,
  'Eastern Region': 10000,
  'Entebbe Area': 8000,
  'Western Region': 10000,
  'Northern Region': 30000,
  'Rest Of Central Region': 7000,
};


const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserCart());
    dispatch(getPprices());
  }, [dispatch]);

  const cartState = useSelector(state => state?.auth?.cartProduct);
  console.log(cartState)
  const printPriceState = useSelector(state => state.printPrice.printPrices);
  console.log(printPriceState);

  const authState = useSelector(state => state?.auth);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [shippingCost, setShippingCost] = useState(0);

 const printPrice = useMemo(() => {
     if (!cartState?.printingPrice ) {
       console.warn('Product does not have a valid printPrice ID.');
       return null; // Or return a default value if needed
     }
   
     const matchedPrintPrice = printPriceState?.find(print => print._id === cartState?.printingPrice);
   
     console.log('Matched Print Price:', matchedPrintPrice); // Check the match
   
     return matchedPrintPrice || null;
   }, [cartState, printPriceState]);


  // Formik initialization
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      address: '',
      region: '',
      subRegion: '',
      paymentMethod: '',  // Payment method is added to capture which option the user selects
    },
    validationSchema: shippingSchema,  // Assuming `shippingSchema` is your Yup validation schema for form fields
    onSubmit: async (values) => {
      if (!cartState || cartState.length === 0) {
        console.error("Cart is empty. Cannot submit order.");
        return;  // Stop further execution if cart is empty
      }
    
      // Process cart items to ensure valid data
      const orderItems = cartState.map(item => {
        if (!item.productId || !item.color) {
          // Skip invalid items
          return null;
        }
    
        return {
          product: item.productId._id,
          quantity: item.quantity,
          //size: item.size ? item.size._id : null,
          size: item.size && item.size._id ? item.size._id : null,
          color: item.color._id,  // Using color ID
          price: item.price,
          instruction: item.instruction,
          uploadedFiles: item.uploadedFiles,
        };
      }).filter(item => item !== null);  // Remove any null values (invalid items)
    
      if (orderItems.length === 0) {
        console.error("No valid items to submit in the order.");
        return;  // Stop further execution if no valid items are available
      }
    
      // Proceed based on payment method
      if (values.paymentMethod === 'Cash on Delivery') {
        dispatch(createAnOrder({
          totalPrice: totalAmount + shippingCost,
          totalPriceAfterDiscount: totalAmount,
          orderItems: orderItems,  // Pass valid order items
          paymentInfo: { paymentMethod: values.paymentMethod },  // Include selected payment method
          shippingInfo: {
            firstName: values.firstName,
            lastName: values.lastName,
            address: values.address,
            region: values.region,
            subRegion: values.subRegion,
          },
        })).unwrap(); 
    
        dispatch(deleteUserCart());
        dispatch(resetState());
        navigate('/my-orders');
        return;
      }
    
      // For other payment methods (e.g., MTN, Airtel, PayPal), navigate to the payment page
      if (values.paymentMethod === 'MTN' || values.paymentMethod === 'Airtel' || values.paymentMethod === 'PayPal') {
        console.log('Navigating to payment page');
        // Navigate to payment page before proceeding with the order creation
        navigate('/payment', { 
          state: { 
            orderItems, 
            totalAmount, 
            shippingCost,
            values 
          } 
        });
        return;  // Stop further execution as navigation has happened
      }
    }
  });


  const getPreparePrice = (printingPriceId) => {
    // Ensure the printingPriceId exists and matches a printPrice
    if (!printingPriceId) {
      return 0; // Return 0 if no printingPriceId is found
    }
  
    const matchedPrintPrice = printPriceState.find(print => print._id === printingPriceId);
    
    if (!matchedPrintPrice) {
      console.warn(`No matching printPrice found for ID: ${printingPriceId}`);
      return 0; // Return 0 if no match is found
    }
    
    // Return the preparePrice if found
    return Number(matchedPrintPrice.preparePrice) || 0;
  };

  useEffect(() => {
    let sum = 0;
    cartState?.forEach(item => {
      const preparePrice = getPreparePrice(item.productId?.printingPrice);  // Get the preparePrice for each item
      sum += (item.quantity * item.price) + preparePrice;  // Add price and preparePrice
    });
    setTotalAmount(sum);
  }, [cartState, printPriceState]);

  useEffect(() => {
    if (authState?.orderedProduct?.order && authState?.orderedProduct?.success) {
      navigate('/my-orders');
    }
  }, [authState, navigate]);



  // Handle region change
  const handleRegionChange = (e) => {
    const region = e.target.value;
    setSelectedRegion(region);
    formik.setFieldValue('region', region);
    formik.setFieldValue('subRegion', '');
    setShippingCost(shippingPrices[region] || 0); // Set the shipping cost
  };

  const getSubRegions = (region) => {
    const subRegions = {
      'Kampala Region': ['Buziga', 'Banda', 'Bakuli', 'Kibuye', 'Katwe', 'Buwate', 'Nakasero', 'Kololo', 'Bwaise','Kanyanya', 'Makerere', 'Luwafu', 'Nsambya', 'Naguru', 'Bugolobi', 'Ntinda', 'Rubaga', 'Nateete', 'Namirembe'],
      'Eastern Region': ['Jinja', 'Mbale', 'Soroti'],
      'Entebbe Area': ['Entebbe City', 'Kajjansi'],
      'Western Region': ['Mbarara', 'Kabale', 'Fort Portal'],
      'Northern Region': ['Gulu', 'Lira', 'Kitgum'],
      'Rest Of Central Region': ['Masaka', 'Mukono', 'Luwero'],
    };
    return subRegions[region] || [];
  };

  const subRegions = getSubRegions(selectedRegion);

  

  return (
    <>
      <Meta title="Checkout" />
      <BreadCrumb title="Checkout" />
      <Container class1='checkout-wrapper'>
        <div className='d-flex check-dis'>
        <div className='checkout-right-data check-none'>
          <div className='checkout-left-data'>
          
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb d-flex gap-10" style={{ "--bs-breadcrumb-divider": ">" }}>
                <li className="breadcrumb-item">
                  <Link to='/cart' className='dark-font'>Cart</Link>
                </li>
                <li className="breadcrumb-item active dark-font" aria-current="page">Information</li>
                <li className="breadcrumb-item">
                  <Link to='/cart' className='active'>Shipping</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Payment</li>
              </ol>
            </nav>
          </div>
          <div className='border-bottom marg-check'>
  {cartState && cartState.map((item, index) => {
    // Get the printing price ID and pass it to getPreparePrice
    const preparePrice = getPreparePrice(item.productId?.printingPrice); // Pass the printingPriceId
    const itemTotalPrice = (item.price * item.quantity) + preparePrice;  // Include the preparePrice in total price calculation

    return (
      <div key={index} className='d-flex align-items-center justify-content-between mb-10'>
        <div className='check-image'>
          <span className='span-cart'>{item.quantity}</span>
          <img src={item?.productId?.images[0]?.url} alt='' className='w-100' />
        </div>
        <div className='check-details'>
          <p className='para-14'>{item.productId.title}</p>
          <ul className="colors">
            <li style={{ backgroundColor: item?.color?.title }}></li>
          </ul>
          <p className='para-14'>{item?.size?.title}</p>
        </div>
        <div className='check-price'>
          <p className='price-style'>UGX <strong>{itemTotalPrice}</strong></p>
        </div>
      </div>
    );
  })}
</div>
            <div className='border-bottom'>
              <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
                <h3 className='grey-font'>SubTotal</h3>
                <p className='price-fin'>UGX <strong>{totalAmount || '0'}</strong></p>
              </div>
              <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
                <h3 className='grey-font'>Shipping</h3>
                <h3 className='font-sub'>UGX {shippingCost}</h3>
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
              <h3>Total</h3>
              <p className='price-fin'>UGX <strong>{totalAmount + shippingCost || '0'}</strong></p>
            </div>
          </div>

          <div className='checkout-left-data f-chech'>
            
            <nav className='none-chech' aria-label="breadcrumb">
              <ol className="breadcrumb d-flex gap-10" style={{ "--bs-breadcrumb-divider": ">" }}>
                <li className="breadcrumb-item">
                  <Link to='/cart' className='dark-font'>Cart</Link>
                </li>
                <li className="breadcrumb-item active dark-font" aria-current="page">Information</li>
                <li className="breadcrumb-item">
                  <Link to='/cart' className='active'>Shipping</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Payment</li>
              </ol>
            </nav>
            
            <h3 className='font-tt'>Choose your Location</h3>
            <form onSubmit={formik.handleSubmit}>
              <div className='loc-inp d-flex gap-10 align-items-center mt-10 mb-10'>
                <div className='form-inputt'>
                  <select
                    className='new-s'
                    name='region'
                    value={formik.values.region}
                    onChange={handleRegionChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="Select region" />
                    <option value="Kampala Region">Kampala Region</option>
                    <option value="Eastern Region">Eastern Region</option>
                    <option value="Entebbe Area">Entebbe Area</option>
                    <option value="Western Region">Western Region</option>
                    <option value="Northern Region">Northern Region</option>
                    <option value="Rest Of Central Region">Rest Of Central Region</option>
                  </select>
                  {formik.touched.region && formik.errors.region ? (
                    <div className="error">{formik.errors.region}</div>
                  ) : null}
                </div>
                <div className='form-inputt'>
                  <select
                    className='new-s'
                    name='subRegion'
                    value={formik.values.subRegion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!selectedRegion}
                  >
                    <option value="" label="Select sub-region" />
                    {subRegions.map((subRegion, index) => (
                      <option key={index} value={subRegion}>
                        {subRegion}
                      </option>
                    ))}
                  </select>
                  {formik.touched.subRegion && formik.errors.subRegion ? (
                    <div className="error">{formik.errors.subRegion}</div>
                  ) : null}
                </div>
              </div>
              <div className='loc-inp d-flex gap-10 mt-10'>
                <div className='form-inputt2'>
                  <input
                    type='text'
                    name='firstName'
                    className='new-s'
                    placeholder='First Name'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="error">{formik.errors.firstName}</div>
                  ) : null}
                </div>
                <div className='form-inputt2'>
                  <input
                    type='text'
                    name='lastName'
                    className='new-s'
                    placeholder='Last Name'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="error">{formik.errors.lastName}</div>
                  ) : null}
                </div>
              </div>
              <div className='form-inputt mt-10'>
                <input
                  type='text'
                  name='address'
                  className='new-s'
                  placeholder='Address'
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className="error">{formik.errors.address}</div>
                ) : null}
              </div>
              <h4 className='font-tt ' style={{ marginTop: '20px', marginBottom: '10px' }}>Choose Payment Method</h4>
              <div className='loc-inpp'>
                
                
                <div
                    className='form-inputt22'
                    onClick={() => document.getElementById('mtnRadio').click()} // Simulate a click on the radio button when the div is clicked
                  >
                    <input
                      type='radio'
                      name='paymentMethod'
                      value='MTN'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.paymentMethod === 'MTN'}
                      id='mtnRadio' // Add an id to the input element
                    />
                    <label htmlFor='mtnRadio'>MTN Mobile Money</label> {/* Wrap the text with a label and link it to the input using htmlFor */}
                  </div>

                  <div
                      className='form-inputt22'
                      onClick={() => document.getElementById('airtelRadio').click()} // Simulate a click on the radio button when the div is clicked
                    >
                      <input
                        type='radio'
                        name='paymentMethod'
                        value='Airtel'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.paymentMethod === 'Airtel'}
                        id='airtelRadio' // Add an id to the input element
                      />
                      <label htmlFor='airtelRadio'>Airtel Mobile Money</label> {/* Wrap the text with a label and link it to the input using htmlFor */}
                    </div>

                    <div
                      className="form-inputt22"
                      onClick={() => document.getElementById('paypalRadio').click()}
                    >
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="PayPal"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.paymentMethod === 'PayPal'}
                        id="paypalRadio"
                      />
                      <span>PayPal</span>
                    </div>

                      <div
                          className='form-inputt22'
                          onClick={() => document.getElementById('codRadio').click()} // Simulate a click on the radio button when the div is clicked
                        >
                          <input
                            type='radio'
                            name='paymentMethod'
                            value='Cash on Delivery'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.paymentMethod === 'Cash on Delivery'}
                            id='codRadio' // Add an id to the input element
                          />
                          <label htmlFor='codRadio'>Cash on Delivery</label> {/* Wrap the text with a label and link it to the input using htmlFor */}
                        </div>
              </div>
              
              {formik.touched.paymentMethod && formik.errors.paymentMethod ? (
                <div className="error">{formik.errors.paymentMethod}</div>
              ) : null}
              
              

              
                          <button type='submit' className='buttontt mbbb'>CONFIRM ORDER</button>
                
              
             
            </form>
          </div>
          
          <div className='checkout-right-data check-mob'>
          <div className='border-bottom'>
            {cartState && cartState.map((item, index) => {
              // Get the printing price ID and pass it to getPreparePrice
              const preparePrice = getPreparePrice(item.productId?.printingPrice);  // Pass the printingPriceId
              const itemTotalPrice = (item.price * item.quantity) + preparePrice;  // Include the preparePrice in total price

              return (
                <div key={index} className='d-flex align-items-center justify-content-between mb-10'>
                  <div className='check-image'>
                    <span className='span-cart'>{item.quantity}</span>
                    <img src={item?.productId?.images[0]?.url} alt='' className='w-100' />
                  </div>
                  <div className='check-details'>
                    <p className='para-14'>{item?.productId?.title}</p>
                    <ul className="colors">
                      <li style={{ backgroundColor: item?.color?.title }}></li>
                    </ul>
                    <p className='para-14'>{item?.size?.title}</p>
                  </div>
                  <div className='check-price'>
                    <p className='price-style'>UGX <strong>{itemTotalPrice}</strong></p> {/* Show item total including preparePrice */}
                  </div>
                </div>
              );
    })}
  </div>
            <div className='border-bottom'>
              <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
                <h3 className='grey-font'>SubTotal</h3>
                <p className='price-fin'>UGX <strong>{totalAmount || '0'}</strong></p>
              </div>
              <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
                <h3 className='grey-font'>Shipping</h3>
                <h3 className='font-sub'>UGX {shippingCost}</h3>
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
              <h3>Total</h3>
              <p className='price-fin'>UGX <strong>{totalAmount + shippingCost || '0'}</strong></p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Checkout;





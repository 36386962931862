import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import printPriceService from "./printPriceService";


// Async Thunks
export const getPprices = createAsyncThunk('printPrice/get-printPrices', async (_, thunkAPI) => {
  try {
    return await printPriceService.getPrintPrices();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || 'Failed to fetch prices');
  }
});



export const resetState = createAction('printPrice/reset-all');

// Initial State
const initialState = {
  printPrices: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  
};

// Reducer Slice
const printPriceSlice = createSlice({
  name: 'printPrices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPprices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPprices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.printPrices = action.payload;
      })
      .addCase(getPprices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      

      .addCase(resetState, () => initialState); // Reset all states to initial state
  },
});

export default printPriceSlice.reducer;
import React, { useEffect, useState } from 'react';
import Meta from '../components/Meta';
import { Link, useNavigate } from 'react-router-dom';
import Container from '../components/Container';
import Custominput from '../components/Custominput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../app/features/user/userSlice';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const loginSchema = yup.object({
  email: yup.string().email('Email should be valid').required('Email Address is required'),
  password: yup.string().required('Password is Required'),
});

const Login = () => {
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(loginUser(values));
    },
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    
    if (authState.user && !authState.isError && authState.isSuccess) {
    window.location.reload();
      navigate('/');
    }
  }, [authState, navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <Meta title='Login' />
      <Container class1='login-wrapper'>
        <div className='col-27'>
          <div className='auth-card'>
            <h3>Login</h3>
            <form action='' onSubmit={formik.handleSubmit} className='formms-log'>
              <Custominput
                type='email'
                name='email'
                placeholder='Email'
                onChange={formik.handleChange('email')}
                onBlur={formik.handleBlur('email')}
                value={formik.values.email}
              />
              <div className='error'>
                {formik.touched.email && formik.errors.email}
              </div>

              <div className='password-container'>
                <Custominput
                  type={passwordVisible ? 'text' : 'password'}
                  name='password'
                  placeholder='Password'
                  onChange={formik.handleChange('password')}
                  onBlur={formik.handleBlur('password')}
                  value={formik.values.password}
                />
                <div className='eye-icon' onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />} {/* Show appropriate eye icon */}
                </div>
              </div>
              <div className='error'>
                {formik.touched.password && formik.errors.password}
              </div>
              {authState.isError && <div className="error">{authState.errorMessage}</div>}
              <div>
                <Link className='marg' to='/forgot-password'>Forgot Password?</Link>
                <div className='d-flex justify-content-center align-items-center gap-15'>
                  <button className='signup-bb' type='submit'>Login</button>
                  <Link to='/signup' className='butt-sign'>SignUp</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Login;
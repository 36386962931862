
import axios from "axios";
import { base_url } from "../../../utils/axiosConfig";

const getPrintPrices = async()=>{
  const response = await axios.get(`${base_url}printPrice/`, );
  
  return response.data;
};




const printPriceService = {
  getPrintPrices,
  

};

export default printPriceService;
import React, { useState } from 'react';  // Import useState hook
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import Container from '../components/Container';
import Custominput from '../components/Custominput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { forgotPasswordTok } from '../app/features/user/userSlice';

const emailSchema = yup.object({
  email: yup.string().email('Email should be valid').required('Email Address is required'),
});

const Forgotpassword = () => {
  const dispatch = useDispatch();

  // State to track if submission was successful
  const [isSuccess, setIsSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailSchema,
    onSubmit: (values) => {
      dispatch(forgotPasswordTok(values))
        .then(() => {
          setIsSuccess(true); // Set success state to true after successful dispatch
        })
        .catch((error) => {
          setIsSuccess(false); // Handle failure if necessary
        });
    },
  });

  return (
    <>
      <Meta title="Forgot Password" />

      <Container class1="login-wrapper">
        <div className="col-27">
          <div className="auth-card">
            <h3>Reset Your Password</h3>
            <p className="reset-p">We will send you an email to reset your password</p>

            {isSuccess && (
              <div className="success-message">
                <p className="text-centerrr">Check your email for the link to reset your password</p>
              </div>
            )}

            <form action="" onSubmit={formik.handleSubmit} className="formms-log">
              <Custominput
                type="email"
                name="email"
                placeholder="Email"
                onChange={formik.handleChange('email')}
                onBlur={formik.handleBlur('email')}
                value={formik.values.email}
              />
              <div className="error text-center">
                {formik.touched.email && formik.errors.email}
              </div>

              <div>
                <div className="d-flex justify-content-center align-items-center gap-15 flex-column">
                  <button className="butt-sign" type="submit">
                    Submit
                  </button>
                  <Link className="marg" to="/login">
                    Cancel
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Forgotpassword;
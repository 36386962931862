import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';
import { Link } from 'react-router-dom';
import mtn from '../images/logo/mobile-mtn.jpg';
import Airtel from '../images/logo/mobile-airtel.jpg';

import { deleteUserCart, getUserCart, resetState, createAnOrder } from '../app/features/user/userSlice';



const PaymentGateway = () => {
  
    const dispatch = useDispatch();
    const navigate = useNavigate();
   
  
    useEffect(() => {
        dispatch(getUserCart());
      }, [dispatch]);
    const cartState = useSelector(state => state?.auth?.cartProduct);
    console.log(cartState[0]?.productId?.title);
    const authState = useSelector(state => state?.auth);
    const [paypalReady, setPaypalReady] = useState(false);
    const [phone, setPhone] = useState('');
    const [momoResponse, setMomoResponse] = useState(null);
    const [momoToken, setMomoToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);
      const [isLoading, setIsLoading] = useState(true); 
      const [orderItems, setOrderItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  
      const location = useLocation();
      const { state } = location;

      useEffect(() => {
        if (state) {
          setOrderItems(state.orderItems);
          setTotalAmount(state.totalAmount);
          setShippingCost(state.shippingCost);
          setPaymentMethod(state.values.paymentMethod);
        
        }
      }, [state]);
      console.log(paymentMethod);

  const getMomoToken = async () => {
    try {
      setLoading(true); // Set loading to true when fetching token
      const response = await axios.post('https://kupto-0a0835a1dfe5.herokuapp.com/api/get-momo-token');
      console.log('MoMo Token:', response.data.momoToken);
      setMomoToken(response.data.momoToken);
  
      // Store token in localStorage
      localStorage.setItem('momoToken', response.data.momoToken);
    } catch (error) {
      console.error('Error fetching MoMo token:', error);
    } finally {
      setLoading(false); // Set loading to false once the request completes
    }
  };
  useEffect(() => {
    const storedToken = localStorage.getItem('momoToken');
    if (storedToken) {
      setMomoToken(storedToken); // Restore token from localStorage
    } else {
      getMomoToken(); // Fetch token if not available in localStorage
    }
  }, []);
  
  useEffect(() => {
    getMomoToken();
  }, []);
 

  const requestToPay = async () => {
    if (!phone) {
      alert('Please enter the Phone Number');
      return;
    }
  
    // Ensure momoToken is available
    if (!momoToken) {
      alert('MoMo token is not available. Please try again later.');
      return;
    }
  
    const finalAmount = totalAmount + shippingCost;
    // Check if totalAmount is valid
    if (!finalAmount || finalAmount <= 0) {
      alert('Invalid totalAmount');
      return;
    }
  
    const body = {
      amount: finalAmount,
      phone: phone,
    };
  
    console.log("Request Data:", body);
  
    try {
      setLoading(true); // Start loading
      const response = await axios.post('https://kupto-0a0835a1dfe5.herokuapp.com/api/request-to-pay', body);
      console.log('MoMo Response:', response.data); // Log the successful response
      setMomoResponse(response.data);
    } catch (error) {
      console.error('Error during payment request:', error);
      if (error.response) {
        console.log('API Response:', error.response.data); // Log the detailed error response
      }
      alert('Payment request failed. Please try again later.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    console.log(momoResponse); // Optionally log the MoMo response to debug
  }, [momoResponse]);

  const toggleDetails = () => {
    setOpenDetails(!openDetails);  // Toggle visibility of the entire cart details
};

useEffect(() => {
  // Check if location.state and location.state.orderItems exist before proceeding
  if (!location.state || !location.state.orderItems) {
    console.error('Order items not found!');
    return;
  }

  // Simulate payment completion (you should replace this with actual payment handling logic)
  const paymentCompleted = true; // Check payment status

  if (paymentCompleted) {
    // After successful payment, submit the order
    dispatch(createAnOrder({
      totalPrice: totalAmount,
      totalPriceAfterDiscount: totalAmount,
      orderItems: location.state.orderItems, // Ensure this exists
      paymentInfo: { method: 'MTN', details: 'Payment details here' }, // Add actual payment details
      shippingInfo: {
        firstName: location.state.values.firstName,
        lastName: location.state.values.lastName,
        address: location.state.values.address,
        region: location.state.values.region,
        subRegion: location.state.values.subRegion,
      },
    }));

    // Clear cart and reset state after order submission
   /* dispatch(deleteUserCart());
    dispatch(resetState());
    navigate('/my-orders');*/
  }
}, [dispatch, totalAmount, navigate, location.state]);


const handlePaypalSuccess = useCallback((details) => {
  // Ensure the PayPal payment details exist before proceeding
  if (!details || !details.payer || !details.paymentID) {
    console.error("PayPal payment details are missing or incomplete.");
    return;
  }

  // Prepare the order data
  const orderItems = cartState?.map(item => {
      if (!item.productId || !item.color) {
        return null; // Skip invalid items
      }

      return {
        product: item.productId._id,
        quantity: item.quantity,
        size: item.size ? item.size._id : null,  // Handle size safely
        color: item.color._id,
        price: item.price,
      };
  }).filter(item => item !== null); // Filter out invalid items

  // Check if orderItems are valid
  if (orderItems.length === 0) {
    console.error("Order items are invalid.");
    return;
  }

  // Dispatch the action to create an order
  dispatch(createAnOrder({
    totalPrice: totalAmount,
    totalPriceAfterDiscount: totalAmount,  // Adjust this if you have discounts
    orderItems: orderItems,
    paymentInfo: { 
      method: 'PayPal', 
      details: details  // Include the payment details from PayPal
    },
    shippingInfo: {
      firstName: location.state.values.firstName,
      lastName: location.state.values.lastName,
      address: location.state.values.address,
      region: location.state.values.region,
      subRegion: location.state.values.subRegion,
    },
  }));

  // Reset cart after successful order submission
  dispatch(deleteUserCart());
  dispatch(resetState());

  // Redirect to orders page
  navigate('/my-orders');
}, [dispatch, totalAmount, cartState, location.state, navigate]);


useEffect(() => {
  if (paymentMethod === 'PayPal' && !paypalReady) {
    const loadPaypalScript = async () => {
      // Check if the PayPal script is already in the DOM
      const scriptAlreadyExists = document.getElementById('paypal-script');
      if (scriptAlreadyExists) {
        console.log('PayPal script already loaded.');
        setPaypalReady(true); // Set PayPal script loaded if already exists
        return; // Exit early if the script is already loaded
      }

      // If the script does not exist, create and load it
      const res = await loadScript('https://www.paypal.com/sdk/js?client-id=AUfaliDwiTlauFiq2TzfVU0AZtjwTad9iYScffpyIbhxz2jLyU3wcfyo8b8Q2PUzlL4GaftsEXSQV2kz');
      if (!res) {
        alert('Failed to load PayPal script.');
      } else {
        setPaypalReady(true); // Set PayPal script loaded
      }
    };

    loadPaypalScript();
  }
}, [paymentMethod, paypalReady]);

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.id = 'paypal-script'; // Add id to check if the script is already added
    script.src = src;
    script.onload = () => resolve(true); // Resolve if script is loaded
    script.onerror = () => resolve(false); // Resolve false if script loading failed
    document.body.appendChild(script); // Append the script tag to body
  });
};

const conversionRate = 3700;
useEffect(() => {
  if (paypalReady && paymentMethod === 'PayPal') {
    console.log('PayPal is ready');  // Check if PayPal is ready

    // Convert total amount + shipping cost to USD
    const amountInUGX = totalAmount + shippingCost;
    const amountInUSD = (amountInUGX / conversionRate);
    
    // Round to 2 decimal places manually
    const roundedAmountInUSD = Math.round(amountInUSD * 100) / 100;

    window.paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: roundedAmountInUSD.toFixed(2),  // Ensure rounded value
              currency_code: 'USD',  // Set the currency code to USD
            },
          }],
        });
      },
      onApprove: async (data, actions) => {
        const details = await actions.order.capture();
        handlePaypalSuccess(details);
      },
      onError: (err) => {
        console.error('PayPal error:', err);
        alert('An error occurred with PayPal payment.');
      },
    }).render('#paypal-button-container');
  }
}, [paypalReady, paymentMethod, handlePaypalSuccess, totalAmount, shippingCost]);




/*{
teeesting
    "reference": "Testing transaction",
    "subscriber": {
        "country": "UG",
        "currency": "UGX",
        "msisdn": "12****89"
    },
    "transaction": {
        "amount": 1000,
        "country": "UG",
        "currency": "UGX",
        "id": "random-unique-id"
    }
} */


return (
  <div className="checkout-container">
    <Meta title="Payment" />
    <BreadCrumb title="Payment" />

    <div className="payment-gateway">
      <div>
        {cartState && cartState.length > 0 ? (
          <>
            <div className='cartde'>
              <p>Order Summary</p>
              <div className="see-d">
                <Link to="#" onClick={toggleDetails} className="see-ll">
                  {openDetails ? 'Hide Details' : 'See Details'}
                </Link>
              </div>

              {openDetails && (
                <div className="item-detailss">
                  {/* Show all product details here */}
                  {cartState.map((item, index) => (
                    <div key={index}>
                      <p>{item.productId?.title}</p>
                      <p dangerouslySetInnerHTML={{ __html: item.productId?.description }} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        ) : (
          <p>No items in the cart</p>
        )}
      </div>

      <div className="payam">
        <p>Total To Pay:</p>
        <p>UGX {totalAmount + shippingCost}</p>
      </div>

      {/* Conditional rendering for MTN payment method */}
      {paymentMethod === 'MTN' && (
        <div className='payback'>
          <div className='maa'>
            <p>Mobile Money</p>
            <img src={mtn} alt='' className='mobilem-icon' />
          </div>

          <div className="phone-input">
            <p className='enterpho'>Enter Phone Number</p>
            <div className='phone-ali'>
              <p>+256</p>
              <div className="phonin">
                <input
                  id="phone"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Insert Mobile number without 0"
                  className="input-field"
                />
              </div>
            </div>
          </div>

          {/* Pay button for MTN */}
          <div className="pay-button">
            <button
              disabled={loading || !momoToken}
              onClick={requestToPay}
              className="btn-pay"
            >
              {loading ? "Processing..." : `Pay Now: UGX ${totalAmount + shippingCost}`}
            </button>
          </div>
        </div>
      )}

      {/* Conditional rendering for Airtel payment method */}
      {paymentMethod === 'Airtel' && (
        <div className='payback'>
          <div className='maa'>
            <p>Mobile Money</p>
            <img src={Airtel} alt='' className='mobilem-icon' />
          </div>

          <div className="phone-input">
            <p className='enterpho'>Enter Phone Number</p>
            <div className='phone-ali'>
              <p>+256</p>
              <div className="phonin">
                <input
                  id="phone"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Insert Mobile number without 0"
                  className="input-field"
                />
              </div>
            </div>
          </div>

          {/* Pay button for Airtel */}
          <div className="pay-button">
            <button
              disabled={loading || !momoToken}
              onClick={requestToPay}
              className="btn-pay"
            >
              {loading ? "Processing..." : `Pay Now: UGX ${totalAmount + shippingCost}`}
            </button>
          </div>
        </div>
      )}

{paymentMethod === 'PayPal' && (
        <div className='payback'>
          
          {/* Pay button for Airtel */}
          <div className="pay-button">
            
            {paymentMethod === 'PayPal' && paypalReady ? (
                  <div id="paypal-button-container"></div>
                ) : (
                  <button type='submit' className='btn-pay'>Pay Now</button>
                )}
          </div>
        </div>
      )}
    </div>
  </div>
);
}

export default PaymentGateway
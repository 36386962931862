import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { filterProducts } from '../app/features/products/productSlice';

function DropdownItem({ category, setOpen }) {
  const navigate = useNavigate();
  const location = useLocation();  // Get the current URL
  const dispatch = useDispatch();

  const handleClick = () => {
    const currentCategory = new URLSearchParams(location.search).get('category');  // Get the current category from URL

    // Check if the current category matches the one clicked
    if (category && category._id) {
      if (currentCategory === category._id) {
        // If already on the same category page, just filter products
        dispatch(filterProducts({ category: category }));
      } else {
        // If on a different category, navigate to the new category and filter products
        navigate(`/product?category=${encodeURIComponent(category._id)}`);
        dispatch(filterProducts({ category: category }));
      }
      setOpen(false); // Close the dropdown after selection
    }
  };

  return (
    <li className='dropdownItem' onClick={handleClick}>
      {category.title}
    </li>
  );
}

export default DropdownItem;
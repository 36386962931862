import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/user/userSlice";
import productReducer from "./features/products/productSlice";
import contactReducer from "./features/contact/contactSlice";
import posterReducer from "./features/posters/posterSlice";
import postReducer from "./features/Posts/postSlice";
import uploadReducer from "./features/upload/uploadSlice";
import printReducer from "./features/PrintOrder/printSlice";
import categoryReducer from "./features/category/categorySlice";
import brandReducer  from "./features/brand/brandSlice";
import colorReducer from "./features/color/colorSlice";
import sizeReducer from "./features/size/sizeSlice";
import printPriceReducer from "./features/printPrice/printPriceSlice"




export const store = configureStore ({
  reducer: {
    auth: authReducer,
    product: productReducer,
    contact: contactReducer,
    poster: posterReducer,
    post: postReducer,
    upload: uploadReducer,
    print: printReducer,
    category: categoryReducer,
    brand: brandReducer,
    color: colorReducer,
    size: sizeReducer,
    printPrice: printPriceReducer,

  },
});
import React from 'react';
//import './SkeletonLoader.css';

const SkeletonLoader = () => (
  <div className="skeleton-loader">
    <div className="skeleton-item skeleton-text"></div>
    <div className="skeleton-item skeleton-text"></div>
    <div className="skeleton-item skeleton-image"></div>
  </div>
);

export default SkeletonLoader;